.products {
  &__opener {
    height: 50vh;
    background-image: url('../images/materieunite_linea-1.jpg');
    background-position: center bottom;
    background-size: cover;
    margin-top: 12px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1px;
    margin-bottom: 11px;
  }

  &__single {
    flex: 0 0 50%;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    text-decoration: none;
    color: black;

    img {
      max-width: 100%;
      height: auto;
    }

    h4 {
      margin: 0;
      padding: 24px 12px 4px;
    }

    p {
      margin: 0;
      padding: 0 12px 24px;
    }
  }
}
