.main-header {
  height: 80px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    height: 48px;

    img {
      height: 100%;
      width: auto;
    }
  }

  .menu-trigger {
    @include button-reset;

    height: 100%;
    width: 80px;
    border-left: 1px solid black;
    padding: 20px;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: black;
      transform: translateX(100%);
      transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      z-index: 0;
    }

    .js-menu-open & {
      &::after {
        transform: translateX(0%);
      }
    }

    .hamburger {
      display: block;
      height: 1px;
      width: 100%;
      background-color: black;
      position: relative;
      z-index: 1;

      &::before {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: black;
        position: absolute;
        top: -12px;
        left: 0;
      }

      &::after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: black;
        position: absolute;
        bottom: -12px;
        left: 0;
      }

      .js-menu-open & {
        background-color: white;
      }
    }
  }
}
