.philosophy {
  &__videocontainer {
    margin-top: 12px;
    display: flex;
    height: 300px;

    @include media("<900px") {
      height: auto;
    }

    .manifesto {
      position: absolute;
      top: 24px;
      left: 24px;
      margin: 0;
      opacity: 0;

      &:first-child {
        opacity: 1;
      }
    }
  }

  &__manifesto {
    flex: 1;
    position: relative;
  }

  &__video {
    flex: 0 0 400px;
    border-right: 1px solid black;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include media("<900px") {
      display: none;
    }
  }

  &__secondcontainer {
    display: flex;
    margin-right: -1px;

    div {
      flex: 0 0 50%;

      &:first-child {
        border-right: 1px solid black;

        @include media("<900px") {
          flex: 0 0 100%;
        }
      }

      &:last-child {
        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        @include media("<900px") {
          display: none;
        }
      }
    }
  }

  &__ourselves {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1px;
    margin-bottom: 11px;
  }

  &__card {
    flex: 0 0 25%;
    border-right: 1px solid black;
    border-bottom: 1px solid black;

    @include media("<600px") {
      flex: 0 0 50%;
    }

    img {
      max-width: 100%;
      height: auto;
      display: block;
      border-bottom: 1px solid black;
    }

    h4 {
      margin: 0;
      padding: 24px 12px 4px;
    }

    p {
      margin: 0;
      padding: 0 12px 24px;
    }
  }
}
