@mixin button-reset {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

@mixin v-guide {
  position: absolute;
  background-color: black;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1px;
  transform: translateX(-50%);

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 12px;
    transform: translate(-50%, -100%);
    background-color: black;
  }

  &::after {
    top: auto;
    bottom: 0;
    transform: translate(-50%, 100%);
  }
}

@mixin h-guide {
  position: absolute;
  background-color: black;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  transform: translateY(-50%);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 12px;
    transform: translate(-100%, -50%);
    background-color: black;
  }

  &::after {
    left: auto;
    right: 0;
    transform: translate(100%, -50%);
  }
}
