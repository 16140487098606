main {
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
}

.board {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  display: flex;
  pointer-events: none;
  z-index: 2;
}

.inner-board {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  // pointer-events: none;
  // z-index: 2;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 12px;
    right: 12px;
    height: 12px;
    background-color: #fff;
    z-index: 1;
    overflow: auto;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.inner-content {
  flex: 1;
  padding: 0 12px;
  overflow: auto;
}

.left-column {
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 12px;
  width: 400px;
  height: calc(100% - 24px);
  overflow: auto;
  z-index: 2;
  background-color: #fff;

  &__title {
    margin: 0;
    padding: 12px 24px;
    border-bottom: 1px solid black;
  }
}

.right-column {
  padding-left: 400px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.track {
  position: absolute;
  z-index: 2;
  border: 1px solid black;

  &:nth-child(2n) {
    top: 0;
    bottom: 0;
    left: 0;
    width: 12px;
  }

  &:nth-child(2n+1) {
    left: 0;
    right: 0;
    top: 0;
    height: 12px;
  }

  &:nth-child(3) {
    bottom: 0;
    top: auto;
  }

  &:nth-child(4) {
    right: 0;
    left: auto;
  }
}

.curtain {
  position: absolute;
  width: 100%;
  top: 12px;
  bottom: 12px;
  transform: translateX(-100%) translateX(-12px);
  background-color: #fff;
  z-index: 3;
  border-left: 1px solid black;
  border-right: 1px solid black;

  .guide {
    @include v-guide;

    &:nth-child(2n) {
      left: 100%;
    }

    &:nth-child(2n + 1) {
      left: 0;
      height: 100%;
    }
  }
}
