.grid {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
  display: flex;
  // pointer-events: none;
  z-index: 2;

  &__content {
    flex: 1;
    position: relative;
  }

  &__square {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    color: black;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transition: opacity 0.35s ease;
    }

    .ready &:hover {
      .overlay {
        opacity: 0;
      }

      color: white;
    }

    h2 {
      font-size: 3rem;
      margin: 0;
    }

    &:nth-child(1) {
      transform-origin: top left;
      transform: scale(0.5);

      h2 {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 20px;
        transform-origin: bottom right;
      }

      .overlay {
        background-color: $azzurro;
      }
    }

    &:nth-child(2) {
      transform-origin: top right;
      transform: scale(0.5);

      h2 {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 20px;
        transform-origin: bottom left;
      }

      .overlay {
        background-color: $giallo;
      }
    }

    &:nth-child(3) {
      transform-origin: bottom left;
      transform: scale(0.5);

      h2 {
        position: absolute;
        right: 0;
        top: 0;
        transform-origin: top right;
        padding: 20px;
      }

      .overlay {
        background-color: $rosa;
      }
    }

    &:nth-child(4) {
      transform-origin: bottom right;
      transform: scale(0.5);

      h2 {
        position: absolute;
        left: 0;
        top: 0;
        padding: 20px;
        transform-origin: top left;
      }

      .overlay {
        background-color: #fff;
      }
    }
  }

  .guide {
    &:nth-child(2n) {
      @include v-guide;
    }

    &:nth-child(2n + 1) {
      @include h-guide;
    }
  }
}
