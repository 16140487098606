.main-nav {
  background-color: black;
  color: #fff;
  position: absolute;
  top: 80px;
  left: 100%;
  width: calc(100% - 80px);
  height: calc(100% - 80px);

  ul {
    list-style: none;
    padding: 0;
  }

  li a {
    color: white;
    font-family: $primary;
    text-decoration: none;
    font-size: 5vw;
    position: relative;

    &::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 0;
      overflow: hidden;
      color: white;
      transition: width 0.35s cubic-bezier(0.86, 0, 0.07, 1);
      white-space: nowrap;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }
  }

  li:nth-child(1) a::before {
    color: $azzurro;
  }

  li:nth-child(2) a::before {
    color: $rosa;
  }

  li:nth-child(3) a::before {
    color: $giallo;
  }

  li:nth-child(4) a::before {
    color: $giallo;
  }
}
