body {
  margin: 0;
  position: relative;
  height: 100vh;
  font-family: $copy;
  overflow: hidden;
}

* {
  box-sizing: border-box;

  :focus {
    outline: none;
  }
}
