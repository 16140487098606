@font-face {
  font-family: 'Sporting Grotesque';
  src: url('../fonts/Sporting_Grotesque-Regular_web.woff') format('woff'), url('../fonts/Sporting_Grotesque-Regular_web.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sporting Grotesque';
  src: url('../fonts/Sporting_Grotesque-Bold_web.woff') format('woff'), url('../fonts/Sporting_Grotesque-Bold_web.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$primary: 'Sporting Grotesque', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$copy: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary;
  line-height: 1.25;
}

.h-guide {
  @include h-guide;

  border: none;
  position: relative;
  transform: none;
  top: auto;
  margin: 0;
}

.v-guide {
  @include v-guide;

  border: none;
  transform: none;
  left: 412px;
  margin: 0;
  height: calc(100% - 24px);
  top: 12px;
  z-index: 2;
}

.textbox {
  padding: 24px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    &:first-child {
      margin-top: 0;
    }
  }
}

.didascalia {
  font-size: 12px;
  line-height: 1.3;
  font-family: $primary;
}

.label {
  font-size: 16px;
  line-height: 1.25;

  &--alt {
    font-family: $primary;
    font-weight: bold;
  }
}

.manifesto {
  font-size: 42px;
  line-height: 1.38;
  font-family: $primary;
  font-weight: bold;
}

.copy,
p {
  font-size: 20px;
  line-height: 1.6;
}

.titoletto {
  font-size: 22px;
  line-height: 1.5;
  font-family: $primary;
  font-weight: bold;

  &--maiusc {
    text-transform: uppercase;
    font-weight: normal;
  }
}

.titolo {
  font-size: 32px;
  line-height: 1.5;
  font-family: $primary;
  font-weight: bold;

  &--maiusc {
    text-transform: uppercase;
    font-weight: normal;
  }
}